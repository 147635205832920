<template>
  <div>
    <contract-list-add-new
      :is-add-new-contract-sidebar-active.sync="isAddNewContractSidebarActive"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>مدخلات</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />
              <b-button
                v-if="$can('create', 'contract')"
                variant="primary"
                :to="{ name: 'contracts-add' }"
              >
                <span class="text-nowrap">إضافة {{ $t("contract") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refContractListTable"
        class="position-relative"
        style="overflow: visible"
        :items="fetchContracts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: duration -->
        <template #cell(duration)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: starts_at -->
        <template #cell(starts_at)="data">
          <span class="text-nowrap">
            {{ data.value.split(" ")[0] }}
            <!-- {{moment(data.value.split(' ')[0], 'YYYY-MM-DD').format('DD/MM/YYYY')}} -->
          </span>
        </template>
        <!-- Column: ends_at -->
        <template #cell(ends_at)="data">
          <span class="text-nowrap">
            {{ data.value.split(" ")[0] }}
            <!-- {{moment(data.value, 'YYYY-MM-DD').format('DD/MM/YYYY')}} -->
          </span>
        </template>
        <!-- Column: client -->
        <template #cell(client)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: Status -->

        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$can('update', 'contract')"
              :to="{ name: 'contracts-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('read', 'contract')"
              :to="{ name: 'contracts-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('data_entry', 'evaluationRequest')"
              @click="download(data.item.id, data.item.reference)"
            >
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">تصدير العقد</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('data_entry', 'evaluationRequest')"
              :to="{
                name: 'contracts-evaluation-request-add',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="PlusCircleIcon" />
              <span class="align-middle ml-50">طلب تقييم</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'contract')"
              @click="confirmDelete(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >عرض من {{ dataMeta.from }} إلى {{ dataMeta.to }} من
              {{ dataMeta.of }} مدخلات</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import * as moment from 'moment';
import ContractListFilters from './ContractListFilters.vue';
import useContractList from './useContractList';
import contractStore from '../contractStore';
import ContractListAddNew from './ContractListAddNew.vue';

export default {
  components: {
    ContractListFilters,
    ContractListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    download(contract_id, reference) {
      this.$toast.info('جاري التنزيل...', {
        autoHide: false,
        appendToast: ToastificationContent,
      });
      store
        .dispatch('contract/downloadContract', {
          id: contract_id,
        })
        .then((response) => {
          setTimeout(() => {
            this.downloadFile(response.data, reference);
          }, 3000);
          this.$toast.success('تم التنزيل بنجاح');
        })
        .catch((error) => {
          console.error('Download error:', error);
          this.$toast.error('حدث خطأ اثناء التنزيل');
        });
    },
    downloadFile(blob, filename) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${filename}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    moment() {
      return moment();
    },
    deleteContract(contract) {
      store
        .dispatch('contract/deleteContract', { id: contract.id })
        .then((response) => {
          this.$emit('refetch-data');
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            contractData.value = undefined;
          }
        });
    },
    confirmDelete(contract) {
      this.$bvModal
        .msgBoxConfirm(`يرجى تأكيد أنك تريد حذف العنصر  ${contract.id}.`, {
          title: 'يرجى التأكيد',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'نعم',
          cancelTitle: 'لا',
          cancelVariant: 'outline-warning',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteContract(contract);
          }
        });
    },
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = 'contract';

    // Register contract
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });

    const isAddNewContractSidebarActive = ref(false);
    const statusOptions = [
      {
        label: 'نشط',
        value: 'نشط',
      },
      {
        label: 'ملغى',
        value: 'ملغى',
      },
      {
        label: 'منتهي',
        value: 'منتهي',
      },
    ];

    const {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContractListTable,
      refetchData,

      // UI
      statusFilter,
    } = useContractList();

    return {
      // Sidebar
      isAddNewContractSidebarActive,

      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContractListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      statusOptions,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
