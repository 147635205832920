<template>
  <b-sidebar
    id="add-new-contract-sidebar"
    :visible="isAddNewContractSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-contract-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          إضافة {{ $t('contract') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- client -->
          <validation-provider
            #default="validationContext"
            name="client"
            rules="required"
          >
            <b-form-group
              label="العميل"
              label-for="client"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="contractData.client"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="clients"
                :reduce="val => val.value"
                :clearable="false"
                input-id="client"
                @input="clientChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end client -->
          <!-- starts_at -->

          <validation-provider
            #default="validationContext"
            vid="starts_at"
            name="starts_at"
            rules="required"
          >
            <b-form-group
              label="تاريخ بداية العقد"
              label-for="starts_at"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="contractData.starts_at"
                :config="config"
                class="form-control invoice-edit-input"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end starts_at -->
          <!-- duration -->

          <validation-provider
            #default="validationContext"
            name="duration"
            rules="required"
          >
            <b-form-group
              label="فترة العقد"
              label-for="duration"
            >
              <b-form-input
                id="duration"
                v-model="contractData.duration"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end duration -->
          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="حالة العقد"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="contractData.status"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
                @input="statusChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end status -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewContractSidebarActive',
    event: 'update:is-add-new-contract-sidebar-active',
  },
  props: {
    isAddNewContractSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    clientChanged(client) {
      this.contractData.client_id = client;
    },
    statusChanged(status) {
      this.contractData.status_id = status;
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          store.dispatch('contract/addContract', this.contractData)
            .then((response) => {
              this.$toast.success(response.data.message);
              this.$emit('refetch-data');
              this.$emit('update:is-add-new-contract-sidebar-active', false);
            });
        }
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            this.$toast.error(err.response.data.message);
            this.$refs.refFormObserver.setErrors(err.response.data.errors);
          } else {
            this.$toast.error(err.response.data.error);
          }
        }
        console.log(err);
      });
    },

  },
  setup(props, { emit }) {
    const blankContractData = {
      client: '',
      starts_at: '',
      duration: '',
      status: '',
    };

    const contractData = ref(JSON.parse(JSON.stringify(blankContractData)));
    const resetcontractData = () => {
      contractData.value = JSON.parse(JSON.stringify(blankContractData));
    };

    const clients = ref([]);
    const res = store.dispatch('contract/fetchClients')
      .then((response) => {
        const { data } = response.data;
        clients.value = data.map((c) => ({ label: c.name, value: c.id, ...c }));
      });

    const statuses = [
      {
        label: 'نشط',
        value: 'نشط',
      },
      {
        label: 'ملغى',
        value: 'ملغى',
      },
      {
        label: 'منتهي',
        value: 'منتهي',
      },
    ];

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontractData);

    return {
      contractData,
      clients,
      statuses,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-contract-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
